// Copied from Storeblocks - loading-indicator-dots
// https://github.com/storeblocks/storeblocks-ng/blob/main/packages/loading-indicator-dots/src/lib/loading-indicator-dots.component.scss
// Version: 16.3.1
// This is used only in index.html for the loading spinner

.spinner-index-container {
  /*
   * Default values result in type = 'Large'
   * Note on --spinner-radius. Is currently hard coded based on const innerRadius in index.tsx
   */
  --spinner-radius: 44;
  --spinner-perimeter: (2 * pi * var(--spinner-radius));
  --spinner-perimeter-full: calc(var(--spinner-perimeter) * 1px);
  --spinner-perimeter-fraction: calc(0.001 * var(--spinner-perimeter) * 1px);
  --spinner-track-color: var(--p-color-static-grey-background);
  --spinner-ticker-color: var(--p-color-static-red-foreground);

  align-items: center;
  color: var(--p-color-text-primary);
  display: flex;
  flex-direction: column;
  gap: var(--p-spacing-08);

  // custom center the spinner
  width: 100%;
  height: 100%;
  position: absolute;
  justify-content: center;

  p {
    margin-top: var(--p-spacing-08);
    white-space: nowrap;
  }

  &.xxsmall {
    --spinner-radius: 8;
    --spinner-container-size: var(--p-spacing-rem-16);
  }

  &.xsmall {
    --spinner-radius: 10;
    --spinner-container-size: 1.375rem;
  }

  &.small {
    --spinner-radius: 11;
    --spinner-container-size: var(--p-spacing-rem-24);
  }

  &.medium {
    --spinner-radius: 24;
    --spinner-container-size: var(--p-spacing-rem-48);

    p {
      font-size: 1rem;
      line-height: 1.375rem;
    }
  }

  &.buttonSmall {
    --spinner-radius: 18;
    --spinner-container-size: var(--p-spacing-rem-18);
  }
  &.buttonMedium {
    --spinner-radius: 20;
    --spinner-container-size: var(--p-spacing-rem-20);
  }
  &.buttonLarge {
    --spinner-radius: 24;
    --spinner-container-size: var(--p-spacing-rem-24);
  }

  & .spinner {
    animation: rotate 5s linear infinite;
  }

  & .spinnerTrack {
    stroke: var(--spinner-track-color);
  }

  & .spinnerInMotion {
    animation: spin infinite 6s ease-in-out;
    stroke: var(--spinner-ticker-color);
    stroke-dasharray: var(--spinner-perimeter);
    transform-origin: 0 0;
  }
}

@keyframes spin {
  0% {
    stroke-dasharray: var(--spinner-perimeter-full), var(--spinner-perimeter-fraction);
    transform: rotate(0deg);
  }

  50% {
    stroke-dasharray: var(--spinner-perimeter-fraction), var(--spinner-perimeter-full);
    transform: rotate(720deg);
  }

  100% {
    stroke-dasharray: var(--spinner-perimeter-full), var(--spinner-perimeter-fraction);
    transform: rotate(1080deg);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(720deg);
  }

  100% {
    transform: rotate(1080deg);
  }
}
