/* Margin classes */
.mt-0 {
  margin-top: 0 !important;
}
.mt-04 {
  margin-top: var(--p-spacing-rem-04) !important;
}
.mt-08 {
  margin-top: var(--p-spacing-rem-08) !important;
}
.mt-12 {
  margin-top: var(--p-spacing-rem-12) !important;
}
.mt-16 {
  margin-top: var(--p-spacing-rem-16) !important;
}
.mt-20 {
  margin-top: var(--p-spacing-rem-20) !important;
}
.mt-24 {
  margin-top: var(--p-spacing-rem-24) !important;
}
.mt-32 {
  margin-top: var(--p-spacing-rem-32) !important;
}
.mt-40 {
  margin-top: var(--p-spacing-rem-40) !important;
}
.mt-48 {
  margin-top: var(--p-spacing-rem-48) !important;
}
.mt-56 {
  margin-top: var(--p-spacing-rem-56) !important;
}
.mt-64 {
  margin-top: var(--p-spacing-rem-64) !important;
}
.mt-96 {
  margin-top: var(--p-spacing-rem-96) !important;
}

.mr-0 {
  margin-right: 0 !important;
}
.mr-04 {
  margin-right: var(--p-spacing-rem-04) !important;
}
.mr-08 {
  margin-right: var(--p-spacing-rem-08) !important;
}
.mr-12 {
  margin-right: var(--p-spacing-rem-12) !important;
}
.mr-16 {
  margin-right: var(--p-spacing-rem-16) !important;
}
.mr-20 {
  margin-right: var(--p-spacing-rem-20) !important;
}
.mr-24 {
  margin-right: var(--p-spacing-rem-24) !important;
}
.mr-32 {
  margin-right: var(--p-spacing-rem-32) !important;
}
.mr-40 {
  margin-right: var(--p-spacing-rem-40) !important;
}
.mr-48 {
  margin-right: var(--p-spacing-rem-48) !important;
}
.mr-56 {
  margin-right: var(--p-spacing-rem-56) !important;
}
.mr-64 {
  margin-right: var(--p-spacing-rem-64) !important;
}
.mr-96 {
  margin-right: var(--p-spacing-rem-96) !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-04 {
  margin-bottom: var(--p-spacing-rem-04) !important;
}
.mb-08 {
  margin-bottom: var(--p-spacing-rem-08) !important;
}
.mb-12 {
  margin-bottom: var(--p-spacing-rem-12) !important;
}
.mb-16 {
  margin-bottom: var(--p-spacing-rem-16) !important;
}
.mb-20 {
  margin-bottom: var(--p-spacing-rem-20) !important;
}
.mb-24 {
  margin-bottom: var(--p-spacing-rem-24) !important;
}
.mb-32 {
  margin-bottom: var(--p-spacing-rem-32) !important;
}
.mb-40 {
  margin-bottom: var(--p-spacing-rem-40) !important;
}
.mb-48 {
  margin-bottom: var(--p-spacing-rem-48) !important;
}
.mb-56 {
  margin-bottom: var(--p-spacing-rem-56) !important;
}
.mb-64 {
  margin-bottom: var(--p-spacing-rem-64) !important;
}
.mb-96 {
  margin-bottom: var(--p-spacing-rem-96) !important;
}

.ml-0 {
  margin-left: 0 !important;
}
.ml-04 {
  margin-left: var(--p-spacing-rem-04) !important;
}
.ml-08 {
  margin-left: var(--p-spacing-rem-08) !important;
}
.ml-12 {
  margin-left: var(--p-spacing-rem-12) !important;
}
.ml-16 {
  margin-left: var(--p-spacing-rem-16) !important;
}
.ml-20 {
  margin-left: var(--p-spacing-rem-20) !important;
}
.ml-24 {
  margin-left: var(--p-spacing-rem-24) !important;
}
.ml-32 {
  margin-left: var(--p-spacing-rem-32) !important;
}
.ml-40 {
  margin-left: var(--p-spacing-rem-40) !important;
}
.ml-48 {
  margin-left: var(--p-spacing-rem-48) !important;
}
.ml-56 {
  margin-left: var(--p-spacing-rem-56) !important;
}
.ml-64 {
  margin-left: var(--p-spacing-rem-64) !important;
}
.ml-96 {
  margin-left: var(--p-spacing-rem-96) !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.my-04 {
  margin-top: var(--p-spacing-rem-04) !important;
  margin-bottom: var(--p-spacing-rem-04) !important;
}
.my-08 {
  margin-top: var(--p-spacing-rem-08) !important;
  margin-bottom: var(--p-spacing-rem-08) !important;
}
.my-12 {
  margin-top: var(--p-spacing-rem-12) !important;
  margin-bottom: var(--p-spacing-rem-12) !important;
}
.my-16 {
  margin-top: var(--p-spacing-rem-16) !important;
  margin-bottom: var(--p-spacing-rem-16) !important;
}
.my-20 {
  margin-top: var(--p-spacing-rem-20) !important;
  margin-bottom: var(--p-spacing-rem-20) !important;
}
.my-24 {
  margin-top: var(--p-spacing-rem-24) !important;
  margin-bottom: var(--p-spacing-rem-24) !important;
}
.my-32 {
  margin-top: var(--p-spacing-rem-32) !important;
  margin-bottom: var(--p-spacing-rem-32) !important;
}
.my-40 {
  margin-top: var(--p-spacing-rem-40) !important;
  margin-bottom: var(--p-spacing-rem-40) !important;
}
.my-48 {
  margin-top: var(--p-spacing-rem-48) !important;
  margin-bottom: var(--p-spacing-rem-48) !important;
}
.my-56 {
  margin-top: var(--p-spacing-rem-56) !important;
  margin-bottom: var(--p-spacing-rem-56) !important;
}
.my-64 {
  margin-top: var(--p-spacing-rem-64) !important;
  margin-bottom: var(--p-spacing-rem-64) !important;
}
.my-96 {
  margin-top: var(--p-spacing-rem-96) !important;
  margin-bottom: var(--p-spacing-rem-96) !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.mx-04 {
  margin-left: var(--p-spacing-rem-04) !important;
  margin-right: var(--p-spacing-rem-04) !important;
}
.mx-08 {
  margin-left: var(--p-spacing-rem-08) !important;
  margin-right: var(--p-spacing-rem-08) !important;
}
.mx-12 {
  margin-left: var(--p-spacing-rem-12) !important;
  margin-right: var(--p-spacing-rem-12) !important;
}
.mx-16 {
  margin-left: var(--p-spacing-rem-16) !important;
  margin-right: var(--p-spacing-rem-16) !important;
}
.mx-20 {
  margin-left: var(--p-spacing-rem-20) !important;
  margin-right: var(--p-spacing-rem-20) !important;
}
.mx-24 {
  margin-left: var(--p-spacing-rem-24) !important;
  margin-right: var(--p-spacing-rem-24) !important;
}
.mx-32 {
  margin-left: var(--p-spacing-rem-32) !important;
  margin-right: var(--p-spacing-rem-32) !important;
}
.mx-40 {
  margin-left: var(--p-spacing-rem-40) !important;
  margin-right: var(--p-spacing-rem-40) !important;
}
.mx-48 {
  margin-left: var(--p-spacing-rem-48) !important;
  margin-right: var(--p-spacing-rem-48) !important;
}
.mx-56 {
  margin-left: var(--p-spacing-rem-56) !important;
  margin-right: var(--p-spacing-rem-56) !important;
}
.mx-64 {
  margin-left: var(--p-spacing-rem-64) !important;
  margin-right: var(--p-spacing-rem-64) !important;
}
.mx-96 {
  margin-left: var(--p-spacing-rem-96) !important;
  margin-right: var(--p-spacing-rem-96) !important;
}

/* Padding classes */
.pt-0 {
  padding-top: 0 !important;
}
.pt-04 {
  padding-top: var(--p-spacing-rem-04) !important;
}
.pt-08 {
  padding-top: var(--p-spacing-rem-08) !important;
}
.pt-12 {
  padding-top: var(--p-spacing-rem-12) !important;
}
.pt-16 {
  padding-top: var(--p-spacing-rem-16) !important;
}
.pt-20 {
  padding-top: var(--p-spacing-rem-20) !important;
}
.pt-24 {
  padding-top: var(--p-spacing-rem-24) !important;
}
.pt-32 {
  padding-top: var(--p-spacing-rem-32) !important;
}
.pt-40 {
  padding-top: var(--p-spacing-rem-40) !important;
}
.pt-48 {
  padding-top: var(--p-spacing-rem-48) !important;
}
.pt-56 {
  padding-top: var(--p-spacing-rem-56) !important;
}
.pt-64 {
  padding-top: var(--p-spacing-rem-64) !important;
}
.pt-96 {
  padding-top: var(--p-spacing-rem-96) !important;
}

.pr-0 {
  padding-right: 0 !important;
}
.pr-04 {
  padding-right: var(--p-spacing-rem-04) !important;
}
.pr-08 {
  padding-right: var(--p-spacing-rem-08) !important;
}
.pr-12 {
  padding-right: var(--p-spacing-rem-12) !important;
}
.pr-16 {
  padding-right: var(--p-spacing-rem-16) !important;
}
.pr-20 {
  padding-right: var(--p-spacing-rem-20) !important;
}
.pr-24 {
  padding-right: var(--p-spacing-rem-24) !important;
}
.pr-32 {
  padding-right: var(--p-spacing-rem-32) !important;
}
.pr-40 {
  padding-right: var(--p-spacing-rem-40) !important;
}
.pr-48 {
  padding-right: var(--p-spacing-rem-48) !important;
}
.pr-56 {
  padding-right: var(--p-spacing-rem-56) !important;
}
.pr-64 {
  padding-right: var(--p-spacing-rem-64) !important;
}
.pr-96 {
  padding-right: var(--p-spacing-rem-96) !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}
.pb-04 {
  padding-bottom: var(--p-spacing-rem-04) !important;
}
.pb-08 {
  padding-bottom: var(--p-spacing-rem-08) !important;
}
.pb-12 {
  padding-bottom: var(--p-spacing-rem-12) !important;
}
.pb-16 {
  padding-bottom: var(--p-spacing-rem-16) !important;
}
.pb-20 {
  padding-bottom: var(--p-spacing-rem-20) !important;
}
.pb-24 {
  padding-bottom: var(--p-spacing-rem-24) !important;
}
.pb-32 {
  padding-bottom: var(--p-spacing-rem-32) !important;
}
.pb-40 {
  padding-bottom: var(--p-spacing-rem-40) !important;
}
.pb-48 {
  padding-bottom: var(--p-spacing-rem-48) !important;
}
.pb-56 {
  padding-bottom: var(--p-spacing-rem-56) !important;
}
.pb-64 {
  padding-bottom: var(--p-spacing-rem-64) !important;
}
.pb-96 {
  padding-bottom: var(--p-spacing-rem-96) !important;
}

.pl-0 {
  padding-left: 0 !important;
}
.pl-04 {
  padding-left: var(--p-spacing-rem-04) !important;
}
.pl-08 {
  padding-left: var(--p-spacing-rem-08) !important;
}
.pl-12 {
  padding-left: var(--p-spacing-rem-12) !important;
}
.pl-16 {
  padding-left: var(--p-spacing-rem-16) !important;
}
.pl-20 {
  padding-left: var(--p-spacing-rem-20) !important;
}
.pl-24 {
  padding-left: var(--p-spacing-rem-24) !important;
}
.pl-32 {
  padding-left: var(--p-spacing-rem-32) !important;
}
.pl-40 {
  padding-left: var(--p-spacing-rem-40) !important;
}
.pl-48 {
  padding-left: var(--p-spacing-rem-48) !important;
}
.pl-56 {
  padding-left: var(--p-spacing-rem-56) !important;
}
.pl-64 {
  padding-left: var(--p-spacing-rem-64) !important;
}
.pl-96 {
  padding-left: var(--p-spacing-rem-96) !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.py-04 {
  padding-top: var(--p-spacing-rem-04) !important;
  padding-bottom: var(--p-spacing-rem-04) !important;
}
.py-08 {
  padding-top: var(--p-spacing-rem-08) !important;
  padding-bottom: var(--p-spacing-rem-08) !important;
}
.py-12 {
  padding-top: var(--p-spacing-rem-12) !important;
  padding-bottom: var(--p-spacing-rem-12) !important;
}
.py-16 {
  padding-top: var(--p-spacing-rem-16) !important;
  padding-bottom: var(--p-spacing-rem-16) !important;
}
.py-20 {
  padding-top: var(--p-spacing-rem-20) !important;
  padding-bottom: var(--p-spacing-rem-20) !important;
}
.py-24 {
  padding-top: var(--p-spacing-rem-24) !important;
  padding-bottom: var(--p-spacing-rem-24) !important;
}
.py-32 {
  padding-top: var(--p-spacing-rem-32) !important;
  padding-bottom: var(--p-spacing-rem-32) !important;
}
.py-40 {
  padding-top: var(--p-spacing-rem-40) !important;
  padding-bottom: var(--p-spacing-rem-40) !important;
}
.py-48 {
  padding-top: var(--p-spacing-rem-48) !important;
  padding-bottom: var(--p-spacing-rem-48) !important;
}
.py-56 {
  padding-top: var(--p-spacing-rem-56) !important;
  padding-bottom: var(--p-spacing-rem-56) !important;
}
.py-64 {
  padding-top: var(--p-spacing-rem-64) !important;
  padding-bottom: var(--p-spacing-rem-64) !important;
}
.py-96 {
  padding-top: var(--p-spacing-rem-96) !important;
  padding-bottom: var(--p-spacing-rem-96) !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.px-04 {
  padding-left: var(--p-spacing-rem-04) !important;
  padding-right: var(--p-spacing-rem-04) !important;
}
.px-08 {
  padding-left: var(--p-spacing-rem-08) !important;
  padding-right: var(--p-spacing-rem-08) !important;
}
.px-12 {
  padding-left: var(--p-spacing-rem-12) !important;
  padding-right: var(--p-spacing-rem-12) !important;
}
.px-16 {
  padding-left: var(--p-spacing-rem-16) !important;
  padding-right: var(--p-spacing-rem-16) !important;
}
.px-20 {
  padding-left: var(--p-spacing-rem-20) !important;
  padding-right: var(--p-spacing-rem-20) !important;
}
.px-24 {
  padding-left: var(--p-spacing-rem-24) !important;
  padding-right: var(--p-spacing-rem-24) !important;
}
.px-32 {
  padding-left: var(--p-spacing-rem-32) !important;
  padding-right: var(--p-spacing-rem-32) !important;
}
.px-40 {
  padding-left: var(--p-spacing-rem-40) !important;
  padding-right: var(--p-spacing-rem-40) !important;
}
.px-48 {
  padding-left: var(--p-spacing-rem-48) !important;
  padding-right: var(--p-spacing-rem-48) !important;
}
.px-56 {
  padding-left: var(--p-spacing-rem-56) !important;
  padding-right: var(--p-spacing-rem-56) !important;
}
.px-64 {
  padding-left: var(--p-spacing-rem-64) !important;
  padding-right: var(--p-spacing-rem-64) !important;
}
.px-96 {
  padding-left: var(--p-spacing-rem-96) !important;
  padding-right: var(--p-spacing-rem-96) !important;
}
