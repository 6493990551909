@use "sass:map";
@use "src/styles/utils/breakpoint";

@import "shepherd.js/dist/css/shepherd.css";
@import "@storeblocks/assets/lib/index.css";
@import "src/styles/utils/spacing";
@import "src/styles/utils/pretzel-overrides.scss";

html,
body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

html {
  overflow-y: auto;
  box-sizing: border-box !important;
  font-family: "Storebrand Text", sans-serif !important;
}

body {
  margin-right: 0 !important; //Overrides scrollbar compansation from menu navbar
  background: var(--p-color-background-grey) !important;
  overflow-y: scroll !important;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

a:hover {
  cursor: pointer;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 var(--p-spacing-rem-16);
  @include breakpoint.for-desktop {
    max-width: #{breakpoint.$screen-xl-min};
  }
}

app-root {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%; // Required to prevent overflowing widths in IE 11

  > * {
    flex-shrink: 0;
  }

  // Make every page component stretch as much as they can in height
  // to easier enable 100% height inside pages
  router-outlet + * {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}

#navbar-root nav {
  background-color: var(--p-color-background-grey) !important;
}

.anchor-stbButton-wrapper {
  text-decoration: none;
}

.full-page-width-limiter {
  max-width: calc(44 * var(--p-spacing-rem-16));
}

.show-only-on-print {
  display: none !important;
}

@include breakpoint.for-print {
  .hide-only-on-print {
    display: none !important;
  }

  .show-only-on-print {
    display: block !important;
  }
}

@import "styles/a11y.scss";
@import "styles/page-init-spinner.scss";

body {
  .styles__surveyContainer {
    width: 100% !important;

    ._hj-widget-container div[role="dialog"] .styles__surveyContainer {
      box-shadow: none !important;
    }

    .inlineSurvey {
      padding: 0px !important;
    }

    .surveyTitle,
    p.thankYouMessage {
      font-family: font-family("Open Sans") !important;
      font-weight: normal !important;
      font-size: 18px !important;
      line-height: 27px !important;
    }

    button.primaryButton.surveyActionButton {
      font-family: font-family("Open Sans") !important;
      font-weight: normal !important;
      font-size: 18px !important;
      line-height: 27px !important;
    }

    button.closeButton.surveysPrimaryButton {
      font-family: font-family("Open Sans") !important;
      font-weight: normal !important;
      font-size: 14px !important;
    }

    textarea.__styles__inputField {
      font-size: 18px !important;
    }
  }
}

// Supporting old design before rebranding
// This is needed since there are FMS keys that have css classes that are not supported in the new design
// TODO - Remove this when the rebranding is finished and the FMS keys are updated with corresponding css classes
.paragraph-long-bold,
.bold {
  font-weight: 600;
}
